import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import DocumentItem from '@/views/DocumentsPage/DocumentItem.vue';
import AddFileModal from '@/components/modals/addFileModal/AddFileModal.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import SortSelect from '@/components/UI/SortSelect.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import { sortDocSelectOptionList } from '@/utils/utils';
import { pluralizeText } from '@/utils/utils';
import LockLabel from '@/components/LockLabel.vue';
import ButtonDivider from '@/components/UI/ButtonDivider.vue';
import FreeTariffButton from '@/components/FreeTariffButton.vue';
import EmptyDocuments from './EmptyDocuments.vue';
let DocumentsPage = class DocumentsPage extends Vue {
    constructor() {
        super(...arguments);
        this.isCheckboxValue = false;
        this.isFileModalOpen = false;
        this.sortedValue = 'По дате загрузки по убыванию';
        this.localDocList = [];
        this.loading = false;
        this.fileModalType = null;
    }
    async mounted() {
        this.loading = true;
        await this.$store.dispatch('storage/loadDocuments');
        this.loading = false;
    }
    function(val) {
        this.localDocList = [...val];
    }
    get documentList() {
        return this.$store.state.storage.documentList;
    }
    get selectOptionList() {
        return sortDocSelectOptionList;
    }
    get documentQuantity() {
        const ru = ['документ', 'документа', 'документов'];
        return `${this.localDocList.length} ${pluralizeText(this.localDocList.length, ru)}`;
    }
    inputSelect(value) {
        const selected = this.selectOptionList.find((el) => el.text === value);
        const { key, text } = selected;
        this.sortedValue = text;
        this.sort(key);
    }
    sort(key) {
        const docs = [...this.localDocList];
        let result = [];
        switch (key) {
            case 'default':
                result = [...docs];
                break;
            case 'desc':
                result = [...docs.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))];
                break;
            case 'asc':
                result = [...docs.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))];
                break;
            case 'dateDesc':
                result = [...docs.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))];
                break;
            case 'dateAsc':
                result = [...docs.sort((a, b) => Date.parse(a.date) - Date.parse(b.date))];
                break;
            case 'type':
                result = [
                    ...docs.sort((c, d) => Date.parse(d.date) - Date.parse(c.date)).sort((a, b) => a.type_doc - b.type_doc),
                ];
                break;
            case 'allowedTrue':
                result = [
                    ...docs
                        .sort((c, d) => Date.parse(d.date) - Date.parse(c.date))
                        .sort((a, b) => +[1, 3].includes(b.status) - +[1, 3].includes(a.status)),
                ];
                break;
            case 'allowedFalse':
                result = [
                    ...docs
                        .sort((c, d) => Date.parse(d.date) - Date.parse(c.date))
                        .sort((a, b) => +[1, 3].includes(a.status) - +[1, 3].includes(b.status)),
                ];
                break;
        }
        this.localDocList = [...result];
    }
    toggleFileModal(val, type) {
        this.isFileModalOpen = val;
        this.fileModalType = type;
    }
    toggleCheckboxValue() {
        this.isCheckboxValue = !this.isCheckboxValue;
        this.filterByAllowProcessing();
    }
    filterByAllowProcessing() {
        if (this.isCheckboxValue) {
            this.localDocList = this.documentList.filter((doc) => [1, 3].includes(doc.status));
        }
        else {
            this.localDocList = [...this.documentList];
        }
    }
};
__decorate([
    Watch('documentList', { deep: true })
], DocumentsPage.prototype, "function", null);
DocumentsPage = __decorate([
    Component({
        components: { CheckboxInput, SortSelect, MainBtn, AddFileModal, DocumentItem, ButtonDivider, LockLabel, FreeTariffButton, ButtonDivider, EmptyDocuments },
    })
], DocumentsPage);
export default DocumentsPage;
