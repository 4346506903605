import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import DialogModal from '@/components/modals/DialogModal.vue';
import EditDocumentModal from '@/components/modals/EditDocumentModal.vue';
import { IRouter } from '@/interfaces/router.interface';
import FileListSliderModal from '@/components/modals/FileListSliderModal.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { format } from 'date-fns';
import { serverDateFormat } from '@/interfaces/api.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import { getDocumentStatus } from '@/utils/docStatus.js';
import { downloadDocs } from '@/utils/downloadDocs.js';
import { pluralizeText, getImageLink } from '@/utils/utils';
import BaseStatus from '@/components/UI/BaseStatus.vue';
import TooltipInfo from '@/components/UI/tooltip/TooltipInfo.vue';
let DocumentItem = class DocumentItem extends Vue {
    constructor() {
        super(...arguments);
        this.isDialogModalOpen = false;
        this.isEditDocumentModalOpen = false;
        this.isFileListSliderModalOpen = false;
        this.showTooltip = false;
    }
    get documentTypes() {
        return this.$store.state.staticVariables.staticVariables?.document_types || [];
    }
    get textDocumentType() {
        const findItem = this.documentTypes.find((documentType) => documentType.value === this.document.type_doc);
        return findItem ? findItem.description : '';
    }
    get fileStatuses() {
        return this.$store.state.staticVariables.staticVariables?.file_statuses;
    }
    get docStatus() {
        return getDocumentStatus(this.document.files);
    }
    get pluralizeText() {
        return pluralizeText;
    }
    get docImageLink() {
        return this.document.files.length ? this.document.files[0].file_link : require('@/assets/Doc.jpg');
    }
    get docFilesQuantity() {
        return `${this.document.files.length} ${pluralizeText(this.document.files.length, [
            'страница',
            'страницы',
            'страниц',
        ])}`;
    }
    get getImageLink() {
        return getImageLink;
    }
    toggleDialogModal(val) {
        this.isDialogModalOpen = val;
    }
    toggleEditDocumentModal(val) {
        this.isEditDocumentModalOpen = val;
    }
    toggleFileListSliderModal(val) {
        this.isFileListSliderModalOpen = val;
    }
    deleteDocument() {
        const isResult = this.$store.dispatch('storage/deleteDocument', this.document.id);
        isResult && this.toggleDialogModal(false);
    }
    editDocument(obj) {
        const { name, date, type_doc, laboratory_id, comment } = obj;
        const payload = {
            id: this.document.id,
            name,
            date: format(new Date(date), serverDateFormat),
            type_doc,
            laboratory_id,
            comment,
            singleDoc: false
        };
        const isResult = this.$store.dispatch('storage/editDocument', payload);
        isResult && this.toggleEditDocumentModal(false);
    }
    get routerLinkTo() {
        return { name: ROUTE_NAME.STORAGE_SINGLE_DOCUMENT_PAGE, params: { id: `${this.document.id}` } };
    }
    downloadFiles() {
        this.document.files.forEach((file) => {
            const { name, date } = this.document;
            downloadDocs(file.file_link, `${name}${date}`);
        });
    }
};
__decorate([
    Prop({ required: true })
], DocumentItem.prototype, "document", void 0);
DocumentItem = __decorate([
    Component({
        components: { MainBtn, FileListSliderModal, EditDocumentModal, DialogModal, BaseStatus, TooltipInfo },
    })
], DocumentItem);
export default DocumentItem;
