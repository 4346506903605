<template lang="pug">
.tooltip-info
  //- icon.tooltip-info__icon(:height="23" :width="23" name="doc-question-icon")
  slot(name="target")
  q-tooltip(
    v-bind="$attrs"
)
    slot(name="text")
    svg(width="6" height="12" viewbox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg").tooltip-tail
      path(d="M6 0.5C6 0.5 3.88162 1.96049 2.63363 3.05065C1.84277 3.74148 0.839221 4.77472 0.230283 5.41699C-0.0753613 5.73936 -0.0770049 6.26243 0.226846 6.58674C0.834461 7.23528 1.8385 8.27974 2.63363 8.97373C3.87808 10.0599 6 11.5 6 11.5L6 0.5Z" fill="currentColor")

</template>

<script>
export default {
  
}
</script>

<style lang="scss">
.tooltip-info {
  display: flex;
}
.tooltip-tail {
  display: none
}

.q-tooltip {
  &._document {
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 18px;
    color: $grey-2;
    background: $light-stroke;
    overflow: visible;
    .tooltip-tail {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-100%, -50%);
      color: $light-stroke;
    }
  } 
  &._feature {
    padding: 7px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 15px;
    color: $light-background;
    background: $grey-3;
    overflow: visible;
    text-align: center;
    .tooltip-tail {
      display: block;
      position: absolute;
      top: -2px;
      left: 50%;
      transform: rotate(90deg) translate(-100%, 25%) ;
      color: $grey-3;
    }
  } 
}




</style>