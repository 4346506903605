<template lang="pug">
.emptyDocuments
  .message-modal__tip._icon(style="background: white") 🙄
  .h2 Пока здесь ничего нет
  .emptyDocuments-content
    span Загружайте результаты анализа или любой другой медицинский документ
    span Вы можете загружать документы только следующих форматов: .pdf, .jpeg, .png и размером до 5мб.
  .emptyDocuments-buttons
    BaseBtn(size="sm" class="storage-page__header-btn" @click="$emit('openModal', 'document')")
      icon(name="add-icon" class="storage-page__header-btn-icon")
      span Загрузить документ
      ButtonDivider
      LockLabel(:locked="$store.state.dashboard.results.length >= 5" white) {{$store.state.dashboard.results.length}}/5

    BaseBtn(size="sm" type="outline" class="storage-page__header-btn _out" @click="$emit('openModal', 'document')")
      span Загрузить анализ
      ButtonDivider
      LockLabel(:locked="$store.state.dashboard.results.length >= 5") {{$store.state.dashboard.results.length}}/5
</template>

<script>
import LockLabel from '@/components/LockLabel.vue';
import ButtonDivider from '@/components/UI/ButtonDivider.vue';

export default {
  components : {
    LockLabel, ButtonDivider
  }
}
</script>

<style lang="scss">
.emptyDocuments {
  @include border-dashed(1, 12, 'D4D1FD', 26);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 28px;
  text-align: center;
  height: 580px;
  margin-top: 34px;
}

.emptyDocuments-content {
  display: flex;
  flex-direction: column;
  gap: 7px
}

.emptyDocuments-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px
}
</style>